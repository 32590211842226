import React from 'react';
import { Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
// import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StarIcon from '@mui/icons-material/Star';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CookieIcon from '@mui/icons-material/Cookie';
import {
    calculateTeamWithMostWins,
    filterTeamsAndPlayersBySeason,
    getTotalAssists,
    getTotalBlocks,
    getTotalNutmegs,
} from '../../../utils/helpers';
import { Player, Team } from '../../../types';
import { LeagueLeadersComponent } from '../../../components/LeagueLeadersComponent';
import { Standings } from '../../../components/Standings';
import { PlayerLeaderBoardComponent } from '../../../components/PlayerLeaderboards';
import { Schedule } from '../../../components/Schedule';
import { GlobalPlayerLeaderBoardComponent } from '../../../components/GlobalPlayerLeaderBoardComponent';
import { MatchesCarousel } from '../../../components/MatchesCarousel';
import { HeroSection } from '../../../components/HeroSection';
import { TOTW } from '../../../components/TOTW';
import { FantasyMiniLeaderboard } from '../../../components/FantasyMiniLeaderBoard';
// import { LeagueLeadersCarousel } from '../../../components/LeagueLeadersCarousel';

export const Home = (): JSX.Element => {
    const [playersInSeason, setPlayersInSeason] = React.useState<Player[] | null>(null);
    const [teamWithMostWins, setTeamWithMostWins] = React.useState<{ team: Team | null; wins: number }>({
        team: null,
        wins: 0,
    });
    const currentDate = new Date();

    const news = useAppSelector((state) => state.news.news);
    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    React.useEffect(() => {
        if (news && teams && players && matchDays && siteData) {
            const tempPlayersInSeason = filterTeamsAndPlayersBySeason(matchDays, teams, players, siteData.seasonUid);
            setPlayersInSeason(tempPlayersInSeason.players);
        }
    }, [news, teams, players, matchDays, siteData]);

    React.useEffect(() => {
        if (teams && matchDays && siteData) {
            const tempTeamWithMostWins: { team: Team | null; wins: number } = calculateTeamWithMostWins(
                teams,
                matchDays.filter((md) => md.seasonUid === siteData.seasonUid),
                'wins'
            );
            setTeamWithMostWins(tempTeamWithMostWins || undefined);
        }
    }, [teams, matchDays, siteData]);

    return (
        <>
            <HeroSection />
            <Container maxWidth='xl'>
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Matches</Typography>
                            <MatchesCarousel
                                matchDays={
                                    matchDays ? matchDays.filter((md) => md.seasonUid === siteData?.seasonUid) : null
                                }
                                teams={teams}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4'>Standings</Typography>
                            <Standings
                                teams={teams}
                                matchDays={
                                    matchDays ? matchDays.filter((md) => md.seasonUid === siteData?.seasonUid) : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4'>Fantasy</Typography>
                            <FantasyMiniLeaderboard />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Typography variant='h4'>Schedule</Typography>
                            <Card>
                                <CardContent>
                                    <Schedule siteData={siteData} matchDays={matchDays} teams={teams} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                            <Typography variant='h4'>Leaderboard</Typography>
                            <PlayerLeaderBoardComponent
                                players={playersInSeason}
                                matchDays={matchDays}
                                seasonUid={siteData?.seasonUid || ' '}
                                teams={teams}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Stats</Typography>
                            <Grid container spacing={0} sx={{ background: 'white' }}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <SportsSoccerIcon fontSize='large' />
                                        <b>MATCHES PLAYED</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>
                                            {
                                                matchDays?.filter((matchDay) => {
                                                    return (
                                                        new Date() > new Date(matchDay.date) &&
                                                        matchDay.seasonUid === siteData?.seasonUid
                                                    );
                                                }).length
                                            }
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography variant='subtitle1'>
                                        <b>TO PLAY</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>
                                            {
                                                matchDays?.filter((matchDay) => {
                                                    return (
                                                        new Date() < new Date(matchDay.date) &&
                                                        matchDay.seasonUid === siteData?.seasonUid
                                                    );
                                                }).length
                                            }
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <EmojiEventsIcon fontSize='large' />
                                        <b>WINS</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>
                                            {
                                                matchDays?.filter((matchDay) => {
                                                    return (
                                                        matchDay.teamAGoals !== matchDay.teamBGoals &&
                                                        matchDay.seasonUid === siteData?.seasonUid
                                                    );
                                                }).length
                                            }
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    {teamWithMostWins && (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={teamWithMostWins.team?.image}
                                                    alt={teamWithMostWins.team?.name}
                                                    width={50}
                                                />
                                                <Typography variant='subtitle1'>
                                                    <b>{teamWithMostWins.team?.name.toLocaleUpperCase()}</b>
                                                </Typography>
                                            </div>
                                            <Typography variant='h5'>
                                                <b>{teamWithMostWins.wins}</b>
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <EqualizerIcon fontSize='large' />
                                        <b>DRAWN</b>
                                    </Typography>
                                    <Typography variant='h6'>
                                        <b>
                                            {
                                                matchDays?.filter((matchDay) => {
                                                    const matchDate = new Date(matchDay.date);
                                                    return (
                                                        matchDate < currentDate &&
                                                        matchDay.teamAGoals === matchDay.teamBGoals &&
                                                        matchDay.seasonUid === siteData?.seasonUid
                                                    );
                                                }).length
                                            }
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <SportsSoccerIcon fontSize='large' />
                                        <b>GOALS</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>
                                            {matchDays
                                                ?.filter((matchDay) => matchDay.seasonUid === siteData?.seasonUid)
                                                .reduce((total, matchDay) => {
                                                    return (
                                                        total +
                                                        parseInt(matchDay.teamAGoals) +
                                                        parseInt(matchDay.teamBGoals)
                                                    );
                                                }, 0)}
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <StarIcon fontSize='large' />
                                        <b>ASSISTS</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>{players && getTotalAssists(players, siteData?.seasonUid)}</b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <DoNotDisturbIcon fontSize='large' />
                                        <b>BLOCKS</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>{players && getTotalBlocks(players, siteData?.seasonUid)}</b>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant='subtitle1'
                                        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                                    >
                                        <CookieIcon fontSize='large' />
                                        <b>NUTMEGS</b>
                                    </Typography>
                                    <Typography variant='h5'>
                                        <b>{players && getTotalNutmegs(players, siteData?.seasonUid)}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} item xs={12}>
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                <Typography variant='h4'>Leaders</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LeagueLeadersComponent
                                            players={players}
                                            teams={teams}
                                            seasonUid={siteData?.seasonUid || ' '}
                                            attributeToFilter='goals'
                                            title='Goals'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LeagueLeadersComponent
                                            players={players}
                                            teams={teams}
                                            seasonUid={siteData?.seasonUid || ' '}
                                            attributeToFilter='assists'
                                            title='Assists'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LeagueLeadersComponent
                                            players={players}
                                            teams={teams}
                                            seasonUid={siteData?.seasonUid || ' '}
                                            attributeToFilter='nutMegs'
                                            title='Nutmegs'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <LeagueLeadersComponent
                                            players={players}
                                            teams={teams}
                                            seasonUid={siteData?.seasonUid || ' '}
                                            attributeToFilter='blocks'
                                            title='Blocks'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TOTW
                                    matchDays={
                                        matchDays
                                            ? matchDays.filter((md) => md.seasonUid === siteData?.seasonUid)
                                            : null
                                    }
                                    players={playersInSeason}
                                    teams={teams}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Global Leaderboard</Typography>
                            <GlobalPlayerLeaderBoardComponent players={players} matchDays={matchDays} teams={teams} />
                        </Grid>
                    </Grid>
                </>
                {/* )} */}
            </Container>
        </>
    );
};
